import React from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import styles from './ProductSizeChartSection.module.css'
import Image from '../../common/Image/Image'
import cx from '../../../utils/className'
import { withDrawer } from '../../common/Drawer/Drawer'

import ProductSizeGuide from './ProductSizeGuide'


const SizeChartContent = props => {
  const { sizeChart, sizeChartTemplate, productTitle } = props
  const sizeChartImgUrl = (sizeChart && sizeChart.imgUrl)

  if (sizeChartTemplate && Object.keys(sizeChartTemplate).length > 0) {
    return <ProductSizeGuide headerTitle={productTitle} {...props} />
  }

  if (sizeChartImgUrl) {
    return (
      <Image
        src={sizeChart.imgUrl}
        title={productTitle ? `${productTitle} conversion chart & size guide` : 'Conversion chart & size guide'}
        original
      ></Image>
    )
  }

  return null
}

const SizeChart = ({ sizeChart, productTitle, $detector }) => {
  const isDesktop = $detector.isDesktop()

  return (
    <div className={cx(styles.sizeChartWrapper, isDesktop && styles.isDesktop)}>
      <div className={styles.scrollArea}>
        <SizeChartContent
          productTitle={productTitle}
          sizeChart={sizeChart}
          />
      </div>
    </div>
  )
}

const SizeChartImageDrawer = withDrawer(withPlugin(SizeChart))

const SizeChartDrawer = withDrawer(withPlugin(ProductSizeGuide))

const SizeChartModal = props => {
  if (!props.sizeChartTemplate || Object.keys(props.sizeChartTemplate).length === 0) {
    return <SizeChartImageDrawer headerTitle={props.$i18n.transl('core.product.sizeGuide')} {...props} />
  }

  return <SizeChartDrawer sizeChartTemplate={props.sizeChartTemplate} headerTitle={props.$i18n.transl('core.product.sizeGuide')} {...props} />
}

const ProductSizeChartSection = ({ onClick, $i18n, $detector }) => {
  const isDesktop = $detector &&  $detector.isDesktop()

  return (
    <h2 className={cx(styles.section, isDesktop && styles.isDesktop)} onClick={onClick}>
      {$i18n.transl('core.product.sizeGuide')}
    </h2>
  )
}

export default ProductSizeChartSection

export {
  SizeChartModal,
  SizeChartContent
}