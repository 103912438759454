import React, { useState, useContext, useRef } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import ProductSection from './ProductSection'
import ProductPhotoSwipe from './ProductPhotoSwipe'
import { FlashDiscountRow } from '../ProductCard/FlashDiscount'
import CommitmentSection from './CommitmentSection'
import ProductSpecSection from './ProductSpecSection'
import KlarnaBar from '../CheckOut/KlarnaBar'
import CouponTagBar from '../Coupon/CouponTagBar'
import { getDiscountText } from '../../../utils/priceUtils'

import PictureDescription from '../PictureDescription/PictureDescription'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { SizeChartContent } from './ProductSizeChartSection'
import { DescriptionContent as DesktopDescriptionContent } from './ProductDescription'
import Button from '../../common/Button/Button'
import { ProductModelContent } from './ProductModel'
import StarRating from './StarRating'

import cx from '../../../utils/className'

import styles from './DesktopProductBasicInfoSection.module.css'

const HOVER_TIMESTAMP = 600

const ProductComment = ({ comment = {}, product, $i18n }) => {
  const { overallCount, star, fallback } = comment
  const ANCHOR_ID = '#ProductCommentSection' // 评论模块

  const starElement = (
    <div className={styles.starRating}>
      <StarRating star={comment.star} />
    </div>
  )

  // if during fetching, or fetch failed, will has the prop "fallback"
  if (fallback || !overallCount) {
    return null
  }else {
    return (
      <span className={cx(styles.reviewRating, styles.clickable)} onClick={() => { window.location = ANCHOR_ID }}>
        {starElement}
        <div className={styles.starRatingDesc}>
          <span>{star}</span>
          <span>({overallCount})</span>
        </div>
      </span>
    )
  }
}


const DesktopProductBasicInfoSection = props => {
  const {
    product,
    comment,
    curImages,
    curImageIndex,
    renderPromotionBar,
    selectedVariants,
    variantInfo,
    minSliderWrapper,
    hideVideo = true,
    children,
    showCouponTag = true
  } = props

  const plugin = useContext(PluginContext)
  const iconRef = useRef()
  const timer = useRef()
  const shareInstance = useRef()

  const { price, msrp, displayPrice = '', displayMsrp = '', displaySaveFee = '' } = variantInfo
  const hasOriginalPrice = msrp && msrp > price

  const [openedAccordionKey, setOpenedAccordionKey] = useState('')

  const imageItems = hideVideo ? curImages.filter(item => item.type !== 'VIDEO') : curImages


  const {
    sizeChart = {},
    description = [],
    feature = {}
  } = product.meta

  const { sizeChartTemplate, models } = product

  const renderSaleElement = (render) => {
    if (typeof render === 'function') {
      return render(product)
    }

    return (
      null
    )
  }

  const handleAccordionChange = props => {
    setOpenedAccordionKey(props[0])
  }

  const renderProductImagesElements = () => {
    if (typeof props.renderProductImages === 'function') {
      return props.renderProductImages(imageItems, product)
    }

    return (
      <div className={styles.leftWrapper}>
        <ProductPhotoSwipe items={imageItems} flexible={props.flexible} curImageIndex={curImageIndex} product={product} minSliderWrapper={minSliderWrapper}>
        </ProductPhotoSwipe>
      </div>
    )
  }

  const renderCommentElement = () => {
    if (typeof props.renderCommentElement === 'function') {
      return props.renderCommentElement(product, comment)
    }
    return <ProductComment product={product} comment={comment} $i18n={plugin.$i18n} />
  }

  const renderButtonElement = () => {
    if (typeof props.renderButton === 'function') {
      return props.renderButton(props)
    }

    if (props.isUpdateMode) {
      return (
        <Button
          onClick={props.onAddToCartClick}
          className={styles.addToCartBtn}>
          {props.$i18n.transl('common.update')}
        </Button>
      )
    } else {
      return (
        <Button
          onClick={props.onAddToCartClick}
          className={cx(styles.addToCartBtn, !product.availableForSale && styles.reminderStyle)}>
          {product.availableForSale ? plugin.$i18n.transl('core.product.addToCart') : plugin.$i18n.transl('core.product.outOfStock')}
        </Button>
      )
    }
  }

  const handleShare = () => {
    const site = plugin.$site.getSiteInfo()

    clearTimeout(timer.current)
    shareInstance.current = plugin.$bridge.multiShare({
      shareInfo: {
        title: `${product.title} | ${site.fullSiteName}`,
        image: product.mainImage ? product.mainImage.src : null,
        url: window.location.href
      },
      eventCategory: 'ProductPage',
      targetRef: iconRef
    })
  }

  const handleCloseShare = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(shareInstance.current.close, HOVER_TIMESTAMP)
  }

  return (
    <ProductSection minSliderWrapper={minSliderWrapper}>
      <div className={cx(styles.wrapper, minSliderWrapper && styles.minSliderWrapper, props.flexible && styles.flexible, props.className)}>
        {renderProductImagesElements()}
        <div className={cx(styles.rightWrapper, minSliderWrapper && styles.minSliderWrapper)}>
          <div className={styles.titleSection}>
            <h1 className={styles.title}>
              {product.isNew && <span className={styles.newTag}>{plugin.$i18n.transl('core.product.new')}</span>}
              {product.title}
            </h1>
            {
              !props.minSliderWrapper && <div className={styles.share} ref={iconRef} onMouseOver={handleShare} onMouseLeave={handleCloseShare}></div>
            }
            {
              product.meta && product.meta.feature && product.meta.feature.promotionTag && <div className={styles.promotionTag}>{product.meta.feature.promotionTag}</div>
            }
            <div className={styles.priceAndCommentWrapper}>
              <div className={styles.priceAndComment}>
                <div className={styles.priceRow}>
                  <div className={cx(styles.salePrice, hasOriginalPrice && styles.highlighted)}>{displayPrice}</div>
                  {
                    hasOriginalPrice
                      ? <span className={styles.originalPrice}>{displayMsrp}</span>
                      : null
                  }
                  {
                    hasOriginalPrice
                      ? <span className={styles.discount}>{getDiscountText(price, msrp)}</span>
                      : null
                  }

                </div>
                {renderCommentElement()}
              </div>
              {
                product.isFlashSale && (
                  <FlashDiscountRow
                    displaySaveFee={displaySaveFee}
                    price={price}
                    msrp={msrp}
                    $i18n={plugin.$i18n}
                    isDesktop
                    className={styles.flashDiscountRowStyle}
                  />
                )
              }
            </div>
            {
              !minSliderWrapper && <KlarnaBar total={price} className={styles.klarna} />
            }
            <div className={styles.promoBar}>
              {renderSaleElement(renderPromotionBar)}
            </div>
          </div>
          {/* {
            product.isFlashSale && <div className={styles.purchaseLimit}>{plugin.$i18n.transl('core.promotion.purchaseLimit')}</div>
          } */}
          {showCouponTag && <CouponTagBar />}
          {
            product.availableForSale && (
              <ProductSpecSection
                product={product}
                items={imageItems}
                selectedVariants={selectedVariants}
                onVariantChanged={props.onOptionSelectedChanged}
                className={styles.specSection}
                showSizeChartModal={props.showSizeChartModal}
                sizeChartTemplate={props.sizeChartTemplate}
                renderSizeLink={props.renderSizeLink}
              />
            )
          }
          <div className={styles.buttonRow}>
            {renderButtonElement()}
            {!minSliderWrapper && props.renderWishButton && props.renderWishButton()}
          </div>
          {
            props.renderLink && props.renderLink()
          }
          {
            !minSliderWrapper && (
              <div>
                <CommitmentSection />
                <Accordion onChange={handleAccordionChange} allowZeroExpanded>
                <AccordionItem uuid="Description">
                    <AccordionItemHeading>
                      <AccordionItemButton className={cx(styles.accordionButton, openedAccordionKey === 'Description' && styles.open)}>{plugin.$i18n.transl('core.product.description')}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accordionPanel}>
                      <DesktopDescriptionContent
                        descList={description}
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                  {
                    ((sizeChart && sizeChart.imgUrl) || (sizeChart && sizeChart.template)) &&
                    <AccordionItem uuid="SizeGuide">
                      <AccordionItemHeading>
                        <AccordionItemButton className={cx(styles.accordionButton, openedAccordionKey === 'SizeGuide' && styles.open)}>{plugin.$i18n.transl('core.product.sizeGuide')}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className={styles.accordionPanel}>
                        <ProductModelContent models={models} />
                        <SizeChartContent
                          isDesktop={true}
                          productTitle={product.title}
                          sizeChart={sizeChart}
                          sizeChartTemplate={sizeChartTemplate}
                          feature={feature}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  }
                </Accordion>
                <PictureDescription graphicDescription={feature.graphicDescription} />
              </div>
            )
          }
          {children}
        </div>
      </div>
    </ProductSection>
  )
}

export default DesktopProductBasicInfoSection
